export default [
  {
    "title": "Nachtcultuur",
    "video": "https://res.cloudinary.com/dnbztsrik/video/upload/v1739219455/saus/Nachtcultuur_w1rqsf.mp4",
    "description": "De Groninger techno scene is prachtig maar wordt niet begrepen, daar wilden wij iets aan doen en daarom hebben we zonder budget, puur uit passie, deze documentaire gemaakt. De film heeft het niet alleen geschopt tot VICE Nederland maar heeft er ook toe aangezet dat er in Groningen nu een nieuwe nachtvisie wordt ontwikkeld.",
    "fullVideo": "https://www.youtube.com/watch?v=RCyg7Uk09OA"
  },
  {
    "title": "Gewoon Kwetsbaar",
    "video": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673113840/saus/GEWOON_KWETSBAAR_awn1zz.mp4",
    "description": "De trailer van onze eerste documentaire! En een heel tof project met als doel om mensen te helpen. Een film over dat we weer normaal tegen elkaar moeten gaan praten en open moeten zijn over wat we voelen. Dit project van was een groots succes met een uitverkochte premiere in het Groninger Forum en nominaties voor de Kring en Ben Feringa award! \n De film was een idee van Deborah Fidder die de journalistieke kant heeft gedaan met montage door Antoine van Straatum en muziek door Joël Werleman. Geregisseerd en geschoten door ons.",
    "fullVideo": "https://player.vimeo.com/video/715207338"
  },
]
