export default [
  {
    "title": "De Energiefixer",
    "video": "https://res.cloudinary.com/dnbztsrik/video/upload/v1683810851/energiefixer_akpmza.mp4",
    "description": `Bij de Energiefixer kun je gratis je huis laten **verduurzamen**, waarom doe je dat dan niet? Misschien is het nog niet duidelijk wat de Energiefixer precies doet, of dat het **echt** **gratis** is, of dat het ook **leuk** is. Na deze video doe je dat dan sowieso wel. 
    
D.O.P: Rick Wetting  
Licht: Ray van der Bas  
Productie & Regie: Saus`,
    "fullVideo": "https://vimeo.com/video/825026555" 
  },
  {
    "title": "Behapp",
    "video": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673113839/saus/BEHAPP_aacykw.mp4",
    "description": "Normaal willen wij snelle en flitsende video’s maken maar deze opdracht voor de universiteit van Groningen vereiste iets heel anders. Het doel van deze video is om het vertrouwen van deelnemers van dit onderzoek te winnen. Deze mensen staan veel persoonlijke data af aan de onderzoeker. Daarom hebben we een kalme, duidelijke, serieuze video gemaakt om ze te overtuigen mee te blijven doen. Dat kunnen wij ook.",
    "fullVideo": "https://player.vimeo.com/video/715172429"
  },
  {
    "title": "Gareth & Lucas",
    "video": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673113840/saus/GARETHLUCAS_tectpw.mp4",
    "description": "Een ski kleding merk wat lanceerde in 2020, vlak voor dat niemand kon gaan skiën door de pandemie. Met dit enorme probleem kwam Gareth & Lucas naar ons toe. Samen met Bruno van der Kraan hebben we deze campagnefilm ontwikkeld om te laten zien dat de flitsende pullies ook voor winterse sporten in Nederland.",
    "fullVideo": "https://player.vimeo.com/video/715173909"
  },
  // {
  //   "title": "De Afvaltest",
  //   "video": "https://res.cloudinary.com/dnbztsrik/video/upload/v1691498008/afvaltest_g9kbcq.mp4",
  //   "description": "De inwoners van de gemeente Utrechtse Heuvelrug moeten het afval beter scheiden. Met dat doel hebben wij deze video serie bedacht: De Afvaltest, waarbij bekende inwoners uit de gemeente binnen korte tijd een verzameling afval moeten scheiden, degene die dit het best doet, wint!",
  //   "fullVideo": "https://youtu.be/4YNeR7zBJi8"
  // },
  // {
  //   "title": "Gewoon Kwetsbaar",
  //   "video": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673113840/saus/GEWOON_KWETSBAAR_awn1zz.mp4",
  //   "description": "De trailer van onze eerste documentaire! En een heel tof project met als doel om mensen te helpen. Een film over dat we weer normaal tegen elkaar moeten gaan praten en open moeten zijn over wat we voelen. Dit project van was een groots succes met een uitverkochte premiere in het Groninger Forum en nominaties voor de Kring en Ben Feringa award! \n De film was een idee van Deborah Fidder die de journalistieke kant heeft gedaan met montage door Antoine van Straatum en muziek door Joël Werleman. Geregisseerd en geschoten door ons.",
  //   "fullVideo": "https://player.vimeo.com/video/715207338"
  // },
  // {
  //   "title": "LICCEN in je zwembroek",
  //   "video": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673113839/saus/KOPJEK_LICCEN_klx96v.mp4",
  //   "description": "Kopjek is een creatieve organisatie die veel vrijheid geeft. De opdracht was enkel: maak een vette aftermovie op jouw manier. Dus dat hebben we  gedaan. Met angst voor mijn apparatuur heb ik tot mijn middel in het water tussen de dansende techno liefhebbers gestaan om de allermooiste shots te maken. De video heeft weken in de edit gezeten tot hij volledig in onze stijl was. Hij is gebruikt als aftermovie en wordt nog steeds gebruikt om nieuwe edities van het feest te promoten.",
  //   "fullVideo": "https://player.vimeo.com/video/715173681"
  // },
  // {
  //   "title": "Wakedream aftermovie",
  //   "video": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673113839/saus/Wakedream_b5cz3p.mp4",
  //   "description": "Het NSK Wakeboarden is ook gelijk een festival. Want een feestje vieren, dat kunnen die wakeboarders wel. Daar hoort natuurlijk een dikke aftermovie bij. Deze video hebben we vooral in de montage uniek gemaakt en de mensen van Wakedream zeiden dat ze nog nooit zo'n goede aftermovie hadden gehad.",
  //   "fullVideo": "https://player.vimeo.com/video/715173725"
  // },
  // {
  //   "title": "The Desmonds - Overtime",
  //   "video": "https://res.cloudinary.com/dnbztsrik/video/upload/v1673113839/saus/Overtime_anm99p.mp4",
  //   "description": "Deze videoclip is helemaal geschoten op videoband, analoog dus. Wij werken graag analoog en zijn een van de weinigen die met VHS banden werken. Het gevoel wat je krijgt bij het kijken van analoge beelden is zo uniek, dat kun je niet namaken met colorgrading en het process wat deze oude camera's met zich meebrengen is net zo gaaf en brengt automatisch enorme creativiteit met zich mee.",
  //   "fullVideo": "https://player.vimeo.com/video/715207220"
  // }
]
