import React from "react";

export const ContactContent = () => {
  return (
    <>
      <h1>Samenwerken?</h1>
      <p>
        Bel mij op <a href="tel:+31622641499">+31 6 22641499</a>
      </p>
      <p>
        Check <a href="https://www.instagram.com/sikko_/">instagram.com/sikko_</a>
      </p>
      <p>
      Of mail naar <a href="mailto:sikko@studiosaus.nl">sikko@studiosaus.nl</a>
      </p>
    </>
  );
};
