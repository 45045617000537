import { VideoTextureProps } from "@react-three/fiber";
import { atom } from "jotai";
import video_urls from "./content/video_urls.json";

type SceneKey = "intro" | "home" | "projects" | "about" | "people" | "contact" | "info" | "docu";
export const sceneAtom = atom<SceneKey>("intro");

export type Video = {
  src: string;
  instant: boolean;
  props: any;
};

export const videoAtom = atom<Video>({
  src: video_urls.scanlines,
  instant: true,
  props: {},
});

type FullVideo = string;

export const videoModalAtom = atom<FullVideo | null>(null);

export const hitsAtom = atom(parseInt(localStorage.getItem("hits") || "0"));
