import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Canvas } from "@react-three/fiber";
import { Camera } from "./Camera";
import { Scene } from "./Scene";
import {
  EffectComposer,
  Bloom,
  Noise,
  Vignette,
} from "@react-three/postprocessing";
import { Overlays } from "./Overlays";
import { VideoModal } from "./VideoModal";
import { AiOutlineRotateRight } from "react-icons/ai";
import { Mobile } from "./mobile/Mobile";
import { Html } from "@react-three/drei";

const App = () => {
  const [requestLandScape, setRequestLandScape] = React.useState(false);
  const [mobile, setMobile] = React.useState(innerWidth < 1024);

  // Hook to listen to the window resize event
  const onResize = React.useCallback(() => {
    const { innerWidth, innerHeight } = window;
    console.log(innerWidth, innerHeight);

    // if((innerWidth < 1024 && innerHeight >= 1024 || window.matchMedia("(orientation: portrait)").matches) && innerHeight >= 1024) {
    if (
      window.matchMedia("(orientation: portrait)").matches &&
      innerHeight >= 1024 && innerWidth >= 768
    ) {
      setRequestLandScape(true);
    } else {
      setRequestLandScape(false);
    }

    if (innerWidth < 1024) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  // Add and remove the event listener
  React.useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  if (requestLandScape) {
    return (
      <div className="request-landscape">
        <div>
          <AiOutlineRotateRight size="50px" />
          <h1>Draai je scherm om onze website te bekijken</h1>
        </div>
      </div>
    );
  }

  if (mobile) {
    return <Mobile />;
  }

  return (
    <div className="canvas-container">
      <Canvas dpr={1}>
        {/* <Stats showPanel={0} className="stats" /> */}
        <Camera />
        <color attach="background" args={["#0b0b0b"]} />
        <ambientLight color="#4e4e4e" />
        <pointLight position={[3, 8, 3]} intensity={50} color="#e6e2d8" />
        <spotLight
          position={[3, 8, 3]}
          color="#fcf6e9"
          angle={0.6}
          distance={9}
          intensity={100}
        />
        <Suspense
          fallback={
            <Html position={[0, 0, -1]}>
              <svg
                width={38}
                height={38}
                viewBox="0 0 38 38"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient
                    x1="8.042%"
                    y1="0%"
                    x2="65.682%"
                    y2="23.865%"
                    id="a"
                  >
                    <stop stopColor="#fff" stopOpacity={0} offset="0%" />
                    <stop
                      stopColor="#fff"
                      stopOpacity=".631"
                      offset="63.146%"
                    />
                    <stop stopColor="#fff" offset="100%" />
                  </linearGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(1 1)">
                    <path
                      d="M36 18c0-9.94-8.06-18-18-18"
                      id="Oval-2"
                      stroke="url(#a)"
                      strokeWidth={2}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="0.9s"
                        repeatCount="indefinite"
                      />
                    </path>
                    <circle fill="#fff" cx={36} cy={18} r={1}>
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="0.9s"
                        repeatCount="indefinite"
                      />
                    </circle>
                  </g>
                </g>
              </svg>
            </Html>
          }
        >
          <Scene position={[0, 0, 0]} />
        </Suspense>
        <EffectComposer>
          <Bloom
            luminanceThreshold={0}
            mipmapBlur
            luminanceSmoothing={0.0}
            intensity={2}
          />
          <Noise opacity={0.02} />
          <Vignette eskil={false} offset={0.2} darkness={0.8} />
        </EffectComposer>
      </Canvas>
      <Overlays />
      <VideoModal />
    </div>
  );
};

createRoot(document.getElementById("root") as HTMLElement).render(<App />);
